export const inputField = {
  alpha: {
    slots: {
      labelStyle: 'leading-5 text-xs text-brand-white -translate-y-2',
      inputStyle:
        'h-[50px] bg-white flex-1 outline-none rounded-lg border   focus:shadow-[3px_3px_5px_0_rgba(0,0,0,0.10)_inset] text-[1rem] px-sm disabled:opacity-[.6] disabled:pointer-events-none placeholder-brand-gray-400 focus:placeholder-brand-secondary-800',
      leadingStyle:
        'absolute top-0 flex justify-center items-center h-[50px] w-[40px] left-0',
      trailingStyle:
        'absolute top-0 flex justify-center items-center h-[50px] w-[40px] right-0',
    },
    variants: {
      leading: {
        true: {
          inputStyle: 'pl-[50px]',
        },
      },
      trailing: {
        true: {
          inputStyle: 'pr-[50px]',
        },
      },
    },
  },
  bravo: {
    slots: {
      labelStyle: 'leading-5 text-xs text-brand-white -translate-y-2',
      inputStyle:
        'h-[50px] bg-white flex-1 outline-none rounded-lg border   focus:shadow-[3px_3px_5px_0_rgba(0,0,0,0.10)_inset] text-[1rem] px-sm disabled:opacity-[.6] disabled:pointer-events-none placeholder-brand-gray-400 focus:placeholder-brand-secondary-800',
      leadingStyle:
        'absolute top-0 flex justify-center items-center h-[50px] w-[40px] left-0',
      trailingStyle:
        'absolute top-0 flex justify-center items-center h-[50px] w-[40px] right-0',
    },
    variants: {
      leading: {
        true: {
          inputStyle: 'pl-[50px]',
        },
      },
      trailing: {
        true: {
          inputStyle: 'pr-[50px]',
        },
      },
    },
  },
  charlie: {
    slots: {
      labelStyle: 'font-p12 text-brand-white -translate-y-1',
      inputStyle:
        'h-[50px] bg-white flex-1 outline-none rounded border   focus:shadow-[3px_3px_5px_0_rgba(0,0,0,0.10)_inset] text-[1rem] px-sm disabled:opacity-[.6] disabled:pointer-events-none placeholder-brand-gray-900 font-p16 focus:placeholder-brand-secondary-800',
      leadingStyle:
        'absolute top-0 flex justify-center items-center h-[50px] w-[40px] left-0',
      trailingStyle:
        'absolute top-0 flex justify-center items-center h-[50px] w-[40px] right-0',
    },
    variants: {
      leading: {
        true: {
          inputStyle: 'pl-[50px]',
        },
      },
      trailing: {
        true: {
          inputStyle: 'pr-[50px]',
        },
      },
    },
  },
}
