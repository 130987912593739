<script setup lang="ts">
import { tv } from 'tailwind-variants'
import { inputField } from './inputFieldStyles'

interface Props {
  leading?: string
  trailing?: string
  errorText?: string
  label?: string
  modelValue?: string | number | null
  disableCalender?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  leading: '',
  trailing: '',
  errorText: '',
  label: '',
  modelValue: '',
  disableCalender: false,
})

const input = ref()
const theme = useRuntimeConfig().public.theme as keyof typeof inputField
const inputFieldStyling = inputField[theme]

const focus = () => {
  input.value.focus()
}

defineExpose({
  focus,
})

const emits = defineEmits(['update:modelValue'])

const slots = useSlots()
const hasLeading = computed(() => !!slots.leading || props.leading !== '')
const hasTrailing = computed(() => !!slots.trailing || props.trailing !== '')

const ui = tv({
  extend: inputFieldStyling,
})

const variants = computed(() => {
  return {
    leading: hasLeading.value,
    trailing: hasTrailing.value,
    errorTextStyle: props.errorText !== '',
  }
})

const { labelStyle, inputStyle, leadingStyle, trailingStyle } = ui(
  variants.value
)
</script>

<template>
  <div>
    <slot name="label">
      <div v-if="label" :class="labelStyle()">
        <label>
          {{ label }}
        </label>
      </div>
    </slot>

    <div class="relative">
      <div v-if="hasLeading" :class="leadingStyle()">
        <slot name="leading">{{ leading }}</slot>
      </div>
      <div v-if="hasTrailing" :class="trailingStyle()">
        <slot name="trailing">{{ trailing }}</slot>
      </div>

      <input
        v-bind="$attrs"
        ref="input"
        :class="inputStyle()"
        :value="modelValue"
        @input="(event) =>
          emits('update:modelValue', (event.target as HTMLInputElement).value)
        "
      />
    </div>
    <CPFormError v-if="errorText" class="mt-2">
      {{ errorText }}
    </CPFormError>
  </div>
</template>
