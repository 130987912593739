<script setup lang="ts">
import { error } from './errorStyles'
const theme = useRuntimeConfig().public.theme as keyof typeof error
const base = error[theme]()
</script>

<template>
  <div :class="base">
    <Icon name="material-symbols:cancel-outline" size="16" /> <slot />
  </div>
</template>
