import { tv } from 'tailwind-variants'

export const error = {
  alpha: tv({
    base: 'p-1.5 rounded-lg bg-brand-tertiary-400 text-brand-white text-xs w-fit flex flex-row items-center gap-1 leading-normal',
  }),
  bravo: tv({
    base: 'px-1.5 py-1 rounded-lg bg-brand-tertiary-400 text-brand-white text-sm w-fit flex flex-row items-center gap-1 leading-normal',
  }),
  charlie: tv({
    base: 'px-1.5 py-1 rounded bg-brand-tertiary-400 text-brand-white text-sm w-fit flex flex-row items-center gap-1 leading-normal',
  }),
}
